<template>
	<div>
		
</div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';

export default {
  name: 'travel',
  bodyClass: 'travel-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
