<template>
  <div>
    <div class="page-header">
      <parallax
        class="page-header-image"
        style="background-image: url('img/landing/landing1.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">LOA League</h1>
          <div class="text-center">
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#pablo" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Who we are?</h2>
            <h5 class="description">
              The Law of Attraction is the most advanced and leading-edge knowledge that is available to man in this time-space and very few truly understand it to be able to teach it. In the last 8 years I have lived and continue to live my life learning, applying, relearning, reapplying knowledge from the best LOA teachers on the planet, in my pursuit for consistent alignment and mastery of the latest tools to attract everything I want from my ever expanding life and share my findings of my experiments and the most rewarding LOA practices through my workshops, living my purpose living and leading by example.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container"
                style="background-image: url('img/landing/landing (1).jpeg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "You can do, be or have anything you want."
                  <br />
                  <br />
                  <small>-Abraham</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/landing/landing2.jpeg')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container"
                style="background-image: url('img/landing/landing (2).jpeg')"
              ></div>
              <h3>
                If that is your calling and you are now ready to claim what is your birthright!
              </h3>
              <p>
                In the last 7 years he has conducted over 160 Transformational workshops based on Law of Attraction and reprogramming the subconscious mind, the maximum anyone has done in India in this period and personally assisted over 6000 people in making breakthroughs in their personal lives. Gaurav is the only Life coach in India to have been part of a workshop with Abraham Hicks in 2017 in France, in 2018 in Greece and in Barcelona in 2019. He was the only Life coach from India to have studied under Bruce Lipton and Gregg Braden in 2019 in Rome.
              </p>
              <p>
                He is a Life Coach’s Life Coach and trains and nurtures Life Coaches and those who have a calling to assist others in their spiritual journey and helps them in creating successful and gratifying careers in the arena of personal development, life coaching and Workshop leadership through his Law of Attraction Teacher Training, Law of Attraction Coach Training and Money Teacher Training and Prosperity Coach Certification Programs.
              </p>
              <p>
                These are times of major change on the planet and times of change are all about adaptability and your willingness to change and is the best environment for new to emerge. While the energy of the planet realigns itself and things all around under pause, I welcome you to use this opportunity to realign your energy and thought process and allow me to take personal ownership to make 2021 your most incredible year of manifestations, yet!!!.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <carousel-section></carousel-section>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Upcoming Workshops</h2>
        <div class="team">
          <div class="row">
            <div v-for="name in ['Law of Attraction Advanced Workshop 2021', 'Law of Attraction Advanced Workshop 2021', 'Law of Attraction Advanced Workshop 2021']" :key="name" class="col-md-4">
              <div class="team-player">
                <img
                  src="img/landing/lamp.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">{{name}}</h4>
                <h6>June 5, 2021, 9:00 AM to June 6, 2021, 6:00 PM GMT+5:30</h6>
                <p class="description">
                  *Prebook Registration open for the 17th Law of Attraction Advanced Workshop: Abraham Workshop, on 5th & 6th June 2021.* The most life empowering opportunity to give you effective control over what you experience in life on planet Earth is here.
                </p>
                <p>
                   <a href="https://www.meetup.com/meetup-group-VCwQeayV/events/277996877/?rv=ea1_v2&_xtd=gatlbWFpbF9jbGlja9oAJDc3NTg0MmFhLWQ1YWYtNGQwZS04NjllLTA1YzBkZWFlOWZkNQ&utm_campaign=event-announce&utm_medium=email&utm_source=promo">Register here</a> 
                   </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--     <div class="section section-about-us">
      <div class="container">
        <div class="title"><h4>Upcoming Workshops</h4></div>
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <template>
             <card style="width: 20rem;">
               <ul slot="raw-content" class="list-group list-group-flush">
                 <li class="list-group-item">
                  <div class="col-sm-2">
                    <img
                      v-lazy="'img/julie.jpg'"
                      alt="Circle Image"
                      class="rounded-circle"
                    />
                  </div>
                  <div class="col-md-8">
                  Law of Attraction Advanced Workshop 2021 <br> June 5, 2021, 9:00 AM to June 6, 2021, 6:00 PM GMT+5:30 <br>


   <a href="https://www.meetup.com/meetup-group-VCwQeayV/events/277996877/?rv=ea1_v2&_xtd=gatlbWFpbF9jbGlja9oAJDc3NTg0MmFhLWQ1YWYtNGQwZS04NjllLTA1YzBkZWFlOWZkNQ&utm_campaign=event-announce&utm_medium=email&utm_source=promo">Register here</a>
  </div></li>
                 <li class="list-group-item">Law of Attraction Basic Workshop 2021 <br> June 5, 2021, 9:00 AM to June 6, 2021, 6:00 PM GMT+5:30 <br>
<a href="https://www.meetup.com/meetup-group-VCwQeayV/events/277996877/?rv=ea1_v2&_xtd=gatlbWFpbF9jbGlja9oAJDc3NTg0MmFhLWQ1YWYtNGQwZS04NjllLTA1YzBkZWFlOWZkNQ&utm_campaign=event-announce&utm_medium=email&utm_source=promo">Register here</a></li>
                 <li class="list-group-item">Law of Attraction Money Workshop 2021 <br> June 5, 2021, 9:00 AM to June 6, 2021, 6:00 PM GMT+5:30 <br>
<a href="https://www.meetup.com/meetup-group-VCwQeayV/events/277996877/?rv=ea1_v2&_xtd=gatlbWFpbF9jbGlja9oAJDc3NTg0MmFhLWQ1YWYtNGQwZS04NjllLTA1YzBkZWFlOWZkNQ&utm_campaign=event-announce&utm_medium=email&utm_source=promo">Register here</a></li>
               </ul>
              </card>
            </template>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Connect with us?</h2>
        <p class="description">Your happiness is very important to us.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import CarouselSection from './components/CarouselSection';

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    CarouselSection,
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
